import { plugins, userMeta } from '@dabble/app';
import { derived, writable } from '@dabble/data/stores/store';
import { createUserMetaTypeStore, UserMetaStore, UserMetaTypeStore } from '@dabble/data/stores/user-meta';
import { UserMeta } from '@dabble/data/types';

const launchDate = 1664604000000; // Oct 1st 2022 in seconds

export interface OnboardingChecklist extends UserMeta {
  hide: boolean;
  seenOnce: boolean;
  completed: boolean;
  rewarded: boolean;
}

interface OnboardingChecklistStore extends UserMetaTypeStore<OnboardingChecklist> {
  completeStep: (name: string) => Promise<OnboardingChecklist>;
}

const { subscription, currentUser } = plugins.stores;

export function createChecklistStore(userMeta: UserMetaStore): OnboardingChecklistStore {
  const { get, update, subscribe } = createUserMetaTypeStore<OnboardingChecklist>(userMeta, 'onboarding-checklist');

  function completeStep(name: string) {
    const list = get();
    list[name] = true;
    return update(list);
  }

  return {
    completeStep,
    get,
    update,
    subscribe,
  };
}

export const checklist = createChecklistStore(userMeta);
plugins.register({ checklist });
export const showOnboardingLearnMore = writable(false);
export const onboardingAvailable = derived(
  [checklist, subscription, currentUser],
  ([checklist, subscription, currentUser]) =>
    subscription?.status === 'trialing' && currentUser?.createdAt > launchDate && !checklist.completed
);
export const showHelp = writable(null);

window.addEventListener('open-write-1000-for-20', () => {
  showOnboardingLearnMore.set(true);
});
window.addEventListener('signup', () => setTimeout(firstTimeSetup, 1000));

function firstTimeSetup() {
  // create checklist
  checklist.update({
    hide: false,
    completed: false,
    rewarded: false,
  });
}

/*
  Specific to Novel Projects
  TODO: remove to settings at some point
*/
export const order = ['createProject', 'createScene', 'writeNote', 'createPlotLine', 'write1000'];
export const items = {
  createProject: {
    text: 'onboarding_create_project',
    help: 'onboarding_help_create_project',
    steps: 2,
    value: checklist.get().checkProject || false,
  },
  createScene: {
    text: 'onboarding_create_scene',
    help: 'onboarding_help_create_scene',
    steps: 5,
    value: checklist.get().createScene || false,
  },
  writeNote: {
    text: 'onboarding_write_note',
    help: 'onboarding_help_write_note',
    steps: 5,
    value: checklist.get().writeNote || false,
  },
  createPlotLine: {
    text: 'onboarding_create_plot_line',
    help: 'onboarding_help_create_plot_line',
    steps: 4,
    value: checklist.get().createPlotLine || false,
  },
  write1000: {
    text: 'onboarding_write_1000',
    help: 'onboarding_help_write_1000',
    steps: 3,
    value: checklist.get().write1000 || false,
  },
};

window.addEventListener('projectcreated', () => {
  if (onboardingAvailable.get()) {
    checklist.update({ createProject: true });
  }
});

window.addEventListener('create-doc', (event: CustomEvent) => {
  const type = event.detail.doc.type;
  if (onboardingAvailable.get()) {
    if (type === 'novel_scene') {
      checklist.update({ createScene: true });
    } else if (type === 'novel_page') {
      checklist.update({ writeNote: true });
    } else if (type === 'novel_plot_line') {
      checklist.update({ createPlotLine: true });
    }
  }
});

window.addEventListener('reached-reward-goal', () => {
  if (plugins.stores.writeGoal.get().readme === 'open-write-1000-for-20') {
    checklist.update({
      write1000: true,
      completed: true,
    });
  }
});

window.addEventListener('reward-applied', () => {
  if (plugins.stores.writeGoal.get().readme === 'open-write-1000-for-20') {
    checklist.update({
      rewarded: true,
    });
  }
});
/* End Novel Specific Setup */
